<template>
  <v-container class="page-login" fill-height style="align-content: center;">
    <v-row>
      <v-col>
        <v-card class="pa-3 page-login__card" elevation="24" style="background: rgba(27, 58, 76, 0.84)">
          <v-card-text>
            <v-form ref="form" class="my-10" lazy-validation v-model="formValid" style="margin-bottom: 10px !important;">
              <v-text-field
                append-icon="mdi-email"
                autocomplete="off"
                name="login"
                type="text"
                required
                outlined
                :label="$vuetify.lang.t('$vuetify.login.username')"
                :placeholder="$vuetify.lang.t('$vuetify.login.username')"
                :rules="formRule.username"
                v-model="formModel.username"
                style="margin-top: 0px;"
                background-color="#BDBFC1"
                class="app-asd"
              />
              <v-text-field
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                autocomplete="off"
                name="password"
                :label="$vuetify.lang.t('$vuetify.login.password')"
                :placeholder="$vuetify.lang.t('$vuetify.login.password')"
                :type="show1 ? 'text' : 'password'"
                :rules="formRule.password"
                required
                outlined
                v-model="formModel.password"
                v-on:keyup.enter="login"
                @click:append="show1 = !show1"
                style="margin-top: 0px;"
                background-color="#BDBFC1"
              />
              <v-switch v-model="formModel._undefined" style="margin-top: 0px;">
                <template v-slot:label>
                  <span class="input__label">MANTENER SESIÓN ACTIVA</span>
                </template>
              </v-switch>
            </v-form>
            <div style="text-align: center;">
              <img :src="require('../assets/img/letras.png')" height="64" alt="Kalan" />
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn large tile color="#BDBFC1" @click="login" :loading="loading">
              {{ $vuetify.lang.t("$vuetify.login.login") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div style="position: absolute; top: 95%; right: 5%; color: white;">Version: 2.0.3.1</div>
  </v-container>
</template>

<script>
export default {
  name: 'PageLogin',
  mounted() {
    this.$vuetify.theme.defaults.light.primary = '#BDBFC1'
  },
  data() {
    return {
      show1: false,
      loading: false,
      formValid: false,
      formModel: {
        username: null,
        password: null,
        _undefined: true,
      },
      formRule: {
        username: [(v) => !!v || this.$vuetify.lang.t('$vuetify.rule.required', ['username'])],
        password: [(v) => !!v || this.$vuetify.lang.t('$vuetify.rule.required', ['password'])],
      },
    };
  },
  computed: {
    prefix() {
      return '';
    },
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$store
          .dispatch('auth/login', this.formModel)
          .then(() => {
            // this.loading = false;
            this.$router.push({ name: 'home' });
          })
          .catch((err) => {
            console.error(err)
            this.loading = false;
          });
      }
    },

    handleSocialLogin() {},
  },
};
</script>

<style lang="scss" scoped>
.page-login {
  max-width: 600px;
  margin: 0 auto;
}
.input__label {
  color: #BDBFC1
}
</style>
